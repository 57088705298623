import { useCallback, useEffect, useState } from "react";
import "../studentsLessonPlan.css";
import timer from "../../../img/timer.svg";
import { Rating } from "./Rating";
import { Alert } from "../../../components";
import blackCrow from "../../../img/blackCrow.svg";

export const ProgressBar = ({ lessonPlanData }) => {
  const [progress, setProgress] = useState(0);
  const [openrate, setOpenrate] = useState(false);
  const [order, setOrder] = useState(1);

  const data = lessonPlanData?.process?.[0]?.actions;

  const tiltle = lessonPlanData?.lessonPlanTopic;
  const currentData = data?.find((board) => board?.order === order);

  const progressPercent = data?.length;
  const oneLenght = 100 / (progressPercent || 0);

  useEffect(() => {
    setProgress(oneLenght);
  }, [oneLenght]);

  const handleNextClick = () => {
    window.scrollTo(0, 0);

    setProgress(progress + oneLenght);
    setOrder(order + 1);
  };

  const handleBackClick = () => {
    window.scrollTo(0, 0);

    if (progress > oneLenght) {
      setProgress(progress - oneLenght);
      if (order !== 1) {
        setOrder(order - 1);
      }
    }
  };

  const handleOpenRating = () => {
    setOpenrate(true);
  };

  const parser = new DOMParser();
  const doc = parser.parseFromString(currentData?.pupilWorkPart, "text/html");

  const tags = doc.querySelectorAll("p, div");
  let content = "";
  tags.forEach((tag) => {
    content += Array.from(tag.childNodes)
      .filter((node) => node.nodeType === Node.TEXT_NODE)
      .map((node) => node.textContent.trim())
      .join("");
  });

  const aTags = doc.querySelectorAll("a");
  const links = [];
  aTags.forEach((aTag) => {
    const href = aTag.getAttribute("href");
    const textContent = aTag.textContent.trim();
    links.push({ href, textContent });
  });

  const handleCloseRate = useCallback(() => {
    setOpenrate(false);
  }, []);

  return (
    <>
      <div className="progress-container">
        <div className="responsive-content">
          <div className="progress-wrapper">
            <div
              className="progress-bar"
              style={{
                width: `${Math.round(progress)}%`,
              }}
            ></div>
          </div>
          <p className="lp-title">{tiltle}</p>
          <div className="borard-header">
            <div className="alert-button-responsive">
              Քայլ {order} / {progressPercent}
            </div>
            {currentData?.duration > 1 && (
              <div className="timer-content">
                <img src={timer} className="timer-img" alt="" />
                {currentData.duration} ր
              </div>
            )}
          </div>
          <div>
            {currentData?.files?.procImages?.files?.length > 0 && (
              <p className="board-responsive">Գրատախտակ</p>
            )}
          </div>

          <div>
            {currentData?.files?.procImages?.files?.length > 0 &&
              currentData.files.procImages.files.map((lp, index) => (
                <img
                  alt=""
                  className="board-image-responsive"
                  key={index}
                  src={`${currentData.files.procImages.urlPath}/${lp}`}
                />
              ))}
          </div>
          {currentData?.studentActionDesc && (
            <>
              <div
                className="sections-student-description student-part"
                style={{ marginTop: 16 }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="imageDiv">
                    <img src={blackCrow} alt="board" />
                  </div>

                  <div className={"clone-image-div"} />
                  <p className="balckboard">
                    Սովորողի գործողության ամփոփ նկարագիր
                  </p>
                </div>
              </div>

              <p className="board-text-responsive">
                {currentData?.studentActionDesc}
              </p>
            </>
          )}

          <hr className="board-line-responsive" />

          <div>
            <p className="board-text-responsive">{content}</p>
            {links.map((link, linkIndex) => (
              <div className="link-content" key={linkIndex}>
                <a
                  className="lessonplan-link"
                  href={link.href}
                  target="_blank"
                  rel="noopener"
                >
                  {link.textContent}
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="back-next">
          {progress !== oneLenght && (
            <button className="alert-button-confirm" onClick={handleBackClick}>
              Նախորդ
            </button>
          )}
          {progress < 100 && (
            <button className="alert-button-confirm" onClick={handleNextClick}>
              Հաջորդ
            </button>
          )}
          {progress >= 100 && (
            <button className="alert-button-confirm" onClick={handleOpenRating}>
              Ավարտ
            </button>
          )}
        </div>
        {openrate && (
          <Alert
            onCloseConfirm={true}
            openConfirm={true}
            confirmContainerClass="adminPageAlert"
            popUpContainerStyles={{ top: "50%" }}
          >
            <Rating
              lessonPlanData={lessonPlanData}
              handleCloseRate={handleCloseRate}
            />
          </Alert>
        )}
      </div>
    </>
  );
};
