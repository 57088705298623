import "../studentsLessonPlan.css";
import timer from "../../../img/timer.svg";
import empty from "../../../img/empty-board.png";
import { useCallback, useState } from "react";
import { MethodDetails } from "./MethodDetails";
import blackCrow from "../../../img/blackCrow.svg";

export const Board = ({ lessonPlanData }) => {
  const [openDetail, setOpenDetail] = useState(false);
  const [currentMethod, setCurrentMethod] = useState();
  const [detailImagesourceFirst, setDetailImagesourceFirst] = useState("");
  const [detailImageSourceSec, setDetailImageSourceSec] = useState("");
  const lessonPlanViewData = lessonPlanData?.process;
  const boardData = lessonPlanViewData?.[0]?.actions;
  const steps = boardData?.length;

  const handleOpenDetail = (lessonPlan) => {
    setCurrentMethod(lessonPlan);
    setOpenDetail(true);
  };

  const handleCloseDetail = useCallback(() => {
    setOpenDetail(false);
  }, []);

  return (
    <>
      {boardData?.map((lessonPlan, index) => {
        return (
          <div className="board-container" key={index}>
            <div className="borard-header">
              <div className="alert-button">
                Քայլ {index + 1}/{steps}
              </div>
              {lessonPlan.duration > 1 && (
                <div className="timer-content">
                  <img src={timer} className="timer-img" alt="timer" />
                  {lessonPlan.duration} ր
                </div>
              )}
            </div>
            <div className="board-desc">
              <div className="board-header">
                {lessonPlan?.files?.procImages?.files?.length > 0 && (
                  <p className="board-title">Գրատախտակ</p>
                )}

                {lessonPlan?.studentActionDesc && (
                  <div
                    className={
                      lessonPlan?.files?.procImages?.files?.length > 0
                        ? "sections-student-description studentActionBoard"
                        : "sections-student-description studentAction"
                    }
                    style={{ marginTop: 16 }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <div className="imageDiv">
                        <img src={blackCrow} alt="board" />
                      </div>

                      <div className={"clone-image-div"} />
                      <p className="balckboard">
                        Սովորողի գործողության ամփոփ նկարագիր
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="board-content">
                <div>
                  <div className="board-images">
                    {lessonPlan?.files?.procImages?.files?.length > 0 ? (
                      lessonPlan.files.procImages.files.map((lp, index) => (
                        <>
                          <img
                            onClick={() => {
                              handleOpenDetail(lessonPlan);
                              setDetailImagesourceFirst(lp);
                              setDetailImageSourceSec(
                                lessonPlan.files.procImages.urlPath
                              );
                            }}
                            className="board-image"
                            key={index}
                            src={`${lessonPlan.files.procImages.urlPath}/${lp}`}
                            alt=""
                          />
                        </>
                      ))
                    ) : (
                      <img src={empty} />
                    )}
                  </div>
                </div>

                <div className="righ-part">
                  {lessonPlan?.studentActionDesc && (
                    <p className="board-text student-text">
                      {lessonPlan?.studentActionDesc}
                    </p>
                  )}
                  <hr className="board-line" />

                  <div
                    className="student-desc"
                    dangerouslySetInnerHTML={{
                      __html: lessonPlan?.pupilWorkPart,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {openDetail && (
        <MethodDetails
          currentMethod={currentMethod}
          steps={steps}
          handleCloseDetail={handleCloseDetail}
          detailImagesourceFirst={detailImagesourceFirst}
          detailImageSourceSec={detailImageSourceSec}
          openDetail={openDetail}
        />
      )}
    </>
  );
};
