import { useState } from "react";
import "../studentsLessonPlan.css";
import emptyStar from "../../../img/empty-star.svg";
import fullStar from "../../../img/full-star.svg";
import { useDispatch } from "react-redux";
import { sendRatingNumber } from "../../../store/student/actions/sendRating";
import close from "../../../img/closebutton.svg";

export const Rating = ({ lessonPlanData, handleCloseRate }) => {
  const [rating, setRating] = useState(0);
  const stars = [1, 2, 3, 4, 5];
  const [finish, setFinish] = useState("");
  const dispatch = useDispatch();

  const lessonPlanDate = lessonPlanData?.expireDate;

  const date = new Date(lessonPlanDate);

  const monthNames = [
    "հունվարի",
    "փետրվարի",
    "մարտի",
    "ապրիլի",
    "մայիսի",
    "հունիսի",
    "հուլիսի",
    "օգոստոսի",
    "սեպտեմբերի",
    "հոկտեմբերի",
    "նոյեմբերի",
    "դեկտեմբերի",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();

  const formattedDate = `${monthNames[monthIndex]} ${day}`;

  const handleClick = (value) => {
    if (finish === "") {
      setRating(value);
    }
  };

  const handleSendRating = () => {
    if (rating !== 0) {
      dispatch(sendRatingNumber(lessonPlanData?.id, rating));
      setTimeout(() => {
        setFinish("Շնորհակալություն");
      }, 500);
    }
  };

  return (
    <div
      className={
        window.innerWidth <= 1120
          ? "raiting-content-responsive"
          : "raiting-content"
      }
    >
      {window.innerWidth <= 1120 && (
        <div className="close-rating">
          <img src={close} alt="close" onClick={handleCloseRate} />
        </div>
      )}
      {!!formattedDate && (
        <>
          <p className="raiting-text">Խնդրում ենք գնահատել դասի ընթացքը</p>
          <p className="available-date">
            Դասը հասանելի կլինի նույն հղումով մինչև {formattedDate}-ը ներառյալ։
          </p>
        </>
      )}

      <div className="raiting">
        {stars.map((index) => (
          <img
            key={index}
            onClick={() => handleClick(index)}
            className="raiting-star"
            src={index <= rating ? fullStar : emptyStar}
          />
        ))}
      </div>
      <div className="confirm-button">
        {finish === "" ? (
          rating === 0 ? (
            <div
              className="alert-button-confirm-disabled"
              onClick={handleSendRating}
            >
              Հաստատել
            </div>
          ) : (
            <div className="alert-button-confirm" onClick={handleSendRating}>
              Հաստատել
            </div>
          )
        ) : (
          <p className="thank">{finish}</p>
        )}
      </div>
    </div>
  );
};
