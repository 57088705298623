import "../tableComponent.css";
import star from "../../../../../img/rating-star.svg";
import userImage from "../../../../../img/user-img.svg";
import subjectImage from "../../../../../img/subject-icon.svg";

export const TableContent = ({ el }) => {
  return (
    <tr className="tr rating-tr table_row">
      <td className="td firstTd">
        <span className="users-name"> {el?.id} </span>
      </td>
      <td className="td topic">
        <div className="topic-container">
          <p className="topic-text">{el?.topic}</p>
        </div>
      </td>
      <td className="td secondTd">
        <div className="user-info">
          <img
            src={el?.userImagePath || userImage}
            alt="user"
            className="user-image"
          />
          <p className="users-name">{el?.userName}</p>
        </div>
      </td>
      <td className="td secondTd">
        <div className="user-info">
          <img
            src={el?.subjectImg || subjectImage}
            alt="subject"
            className="subject-image"
          />
        </div>
      </td>
      <td className="td secondTd">
        <span className="users-name"> {el?.class} </span>
      </td>
      <td className="td secondTd">
        {el?.views !== 0 ? (
          <div className="views">
            <p className="rating-view">{el?.views} </p>
          </div>
        ) : (
          <div></div>
        )}
      </td>
      <td className="td secondTd">
        {el?.rate ? (
          <div className="rate">
            <img src={star} alt="star" />
            <p className="rating-text">{el?.rate}</p>
          </div>
        ) : (
          <div></div>
        )}
      </td>
      <td className="td secondTd last-td">
        <p className="users-view">{el?.state}</p>
      </td>
    </tr>
  );
};
